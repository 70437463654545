import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Label,
} from "reactstrap";
import { Input } from "../../Components/atoms/input";
import { Input as BootstrapInput } from "reactstrap";
import { login } from "../../api/auth";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import useHookForm from "../../hooks/useHookForm";
import createSchema from "../../helpers/createSchema";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
const schema = createSchema({
  phoneNumber: "phone",
  password: "auth_password",
});
const values = {
  phoneNumber: "998",
  password: "",
  isTrusted: true,
  deviceId: uuidv4(),
  deviceType: 3,
  displayName: navigator.userAgent,
  userType: 3,
  otp: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [change, setChange] = useState(true);
  const navigate = useNavigate();
  const clients = useQueryClient();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useHookForm(values, schema);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheck(checked);
    setValue("isTrusted", checked);
  };

  const onSubmit = handleSubmit(async (res) => {
    setLoading(false);
    const loginData = {
      ...res,
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const res = await login(loginData, "POST");
      toast(res.result.sentText, { type: "success", theme: "colored" });
      setChange(false);
      clients.invalidateQueries({ queryKey: ["profile"] });
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  });

  const onSubmitOtp = handleSubmit(async (res) => {
    setLoading(true);
    const loginData = {
      ...res,
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };
    try {
      const response = await login(loginData, "PUT");
      localStorage.setItem("token", response.result.token);
      navigate("/");
      clients.invalidateQueries({ queryKey: ["profile"] });
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  });

  document.title = "Авторизация | Taqsim Admin";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <ToastContainer position="top-center" />
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5} className="mt-5">
              <Card className="mt-5">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t("welcome")}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    {change ? (
                      <Form action="#" onSubmit={onSubmit}>
                        <div className="mb-3">
                          <Input
                            name="phoneNumber"
                            control={control}
                            errors={errors}
                            label="phone"
                            inputProps={{
                              placeholder: t("phone"),
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Input
                            name="password"
                            control={control}
                            errors={errors}
                            label="password"
                            inputProps={{
                              type: "password",
                              placeholder: t("password"),
                            }}
                          />
                        </div>

                        <div className="form-check">
                          <BootstrapInput
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck2"
                            name="isTrusted"
                            onChange={onChange}
                            checked={check}
                          />{" "}
                          <Label className="form-check-label" for="formCheck2">
                            {t("Trust this device")}
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            {t("sign_in")}
                          </Button>
                        </div>
                      </Form>
                    ) : (
                      <Form onSubmit={onSubmitOtp}>
                        <div className="mb-3">
                          <Input
                            name="otp"
                            control={control}
                            errors={errors}
                            label="Otp"
                            inputProps={{
                              type: "text",
                              placeholder: t("password"),
                            }}
                          />
                        </div>
                        <Button>
                          {loading ? (
                            <Spinner size="sm" className="me-2">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          ) : null}
                          {t("sign_in")}
                        </Button>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
    // {/* </ToastContainer> */}
  );
};

export default Login;
