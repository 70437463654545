import { useQuery } from "@tanstack/react-query";
import {
  getCategories,
  getClients,
  getContracts,
  getEmployees,
  getModeration,
  getModerationId,
  getOrders,
  getPosition,
  getProducts,
  getProfile,
  getReferencesFormal,
  getRegions,
} from "../api/index";

export function getOrdersQuery(params) {
  return {
    queryKey: ["orders", params],
    queryFn: async () => getOrders(params),
  };
}

export function getModerationsQuery(params) {
  return {
    queryKey: ["moderations", params],
    queryFn: async () => getModeration(params),
  };
}

export function getModerationsIdQuery(id) {
  return {
    queryKey: ["moderations", id],
    queryFn: async () => getModerationId(id),
  };
}

export function getProductsQuery(params) {
  return {
    queryKey: ["products", params],
    queryFn: async () => getProducts(params),
  };
}

export function getContractsQuery(params) {
  return {
    queryKey: ["contracts", params],
    queryFn: async () => getContracts(params),
  };
}

export function getProfileQuery(params) {
  return {
    queryKey: ["profile", params],
    queryFn: async () => getProfile(params),
  };
}

export function getClientsQuery(params) {
  return {
    queryKey: ["clients", params],
    queryFn: async () => getClients(params),
  };
}

export function getEmployessQuery(params) {
  return {
    queryKey: ["employees", params],
    queryFn: async () => getEmployees(params),
  };
}

export function getPositionQuery(params) {
  return {
    queryKey: ["position", params],
    queryFn: async () => getPosition(params),
  };
}

export function getReferenceFormalQuery(params) {
  return {
    queryKey: ["reference-formal", params],
    queryFn: async () => getReferencesFormal(params),
  };
}

export function getRegionsQuery(params) {
  return {
    queryKey: ["regions", params],
    queryFn: async () => getRegions(params),
  };
}


export function getCategoriesQuery(params) {
  return {
    queryKey: ["categories", params],
    queryFn: async () => getCategories(params),
  };
}