import React, { useMemo } from "react";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getClientsQuery } from "../../../queries";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";

const Clients = () => {
  const schema = createSchema({});
  const values = {
    desc: true,
    size: 15,
    page: 1,
    findFieldType: 3,
    fieldValue: "",
  };
  const { t } = useTranslation();
  const { watch, setValue } = useHookForm(values, schema);
  const { data, isFetched } = useQuery({
    ...getClientsQuery({
      desc: watch("desc"),
      size: watch("size"),
      page: watch("page"),
      findFieldType: watch("findFieldType"),
      fieldValue: watch("fieldValue"),
    }),
  });

  const handleSearchChange = (e) => {
    setValue("fieldValue", e.target.value);
  };

  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      filterable: false,
      Cell: (cell) => {
        return (
          <Link
            to={`/clients/${cell?.value}`}
            className="fw-medium link-primary"
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: t("Full name of the client"),
      accessor: (row) =>
        row?.lastName + " " + row?.firstName + " " + row?.middleName,
      filterable: false,
    },

    {
      Header: t("Phone number"),
      accessor: "phoneNumber",
      filterable: false,
    },
    {
      Header: t("Passport series"),
      accessor: "passportSerial",
      filterable: false,
    },

    {
      Header: t("Passport number"),
      accessor: "passportNumber",
      filterable: false,
    },

    {
      Header: t("PINFL/INN"),
      accessor: "pinfl",
      filterable: false,
    },
  ]);
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
              <h2>{t("Clients")}</h2>
              <div className="d-flex align-items-center gap-3 ">
                <Input
                  type="number"
                  value={watch("fieldValue")}
                  onChange={handleSearchChange}
                  placeholder={t("Search by phone number")}
                  className=" form-control "
                  id="searchInput"
                />
{/* 
                <Button className="d-flex gap-1 btn btn-success align-items-center w-75">
                  {t("Export to Excel")}
                  <i className=" ri-arrow-right-s-line "></i>
                </Button> */}
                {/* <Button className="d-flex gap-1 btn btn-light align-items-center">
                  {t("Filter")}
                  <i className=" ri-equalizer-fill "></i>
                </Button> */}
              </div>
            </div>
            <div>
              {isFetched ? (
                <TableContainer
                  pagination={{
                    currentPage: watch("page"),
                    totalPages: data?.pagination?.TotalPages,
                    onChange: (page) => setValue("page", page),
                  }}
                  columns={columns}
                  data={data?.result.items}
                  isAddUserList={false}
                  divClass="table-responsive table-card mb-1 "
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light text-muted"
                />
              ) : (
                <Loader />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
