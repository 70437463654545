import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getEmployessQuery } from "../../../queries";
import React, { useMemo } from "react";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap";
import createSchema from "../../../helpers/createSchema";
import useHookForm from "../../../hooks/useHookForm";
import { Link, useNavigate } from "react-router-dom";
const Employees = () => {
  const { t } = useTranslation();
  const schema = createSchema({});
  const values = {
    desc: true,
    size: 15,
    page: 1,
  };
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getEmployessQuery(),
  });

  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      filterable: false,
    },
    {
      Header: t("Full name of the client"),
      accessor: (row) =>
        row?.lastName + " " + row?.firstName + " " + row?.middleName,
      filterable: false,
      Cell: ({ row }) => (
        <Link to={`/employees/${row.original.id}`}>
          {row?.original.lastName +
            " " +
            row?.original.firstName +
            " " +
            row?.original.middleName}
        </Link>
      ),
    },

    {
      Header: t("Phone number"),
      accessor: "phoneNumber",
      filterable: false,
    },
    {
      Header: t("Job title"),
      accessor: (row) => row?.position?.name,
      filterable: false,
    },

    {
      Header: t("Name of the organization"),
      accessor: (row) => row?.organization?.information?.organizationName,
      filterable: false,
    },

    {
      Header: t("Status"),
      accessor: "state",
      filterable: false,
      Cell: ({ value }) => {
        switch (value) {
          case 0:
            return <p>{t("Not active")}</p>;
          case 1:
            return <p>{t("Active")}</p>;
        }
      },
    },
  ]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
              <h2>{t("Employees")}</h2>
              <div className="d-flex align-items-center gap-3 ">
                {/* <Button className="d-flex gap-1 btn btn-success align-items-center ">
                  {t("Export to Excel")}
                  <i className=" ri-arrow-right-s-line "></i>
                </Button>
                <Button className="d-flex gap-1 btn btn-light align-items-center">
                  {t("Filter")}
                  <i className=" ri-equalizer-fill "></i>
                </Button>{" "} */}
                <Button
                  onClick={() => navigate("/employees/add")}
                  className="d-flex gap-1 btn btn-primary align-items-center"
                >
                  {t("Adding an employee")}
                  <i className=" ri-add-line"></i>
                </Button>
              </div>
            </div>
            <div>
              {isFetched ? (
                <TableContainer
                  pagination={{
                    currentPage: watch("page"),
                    totalPages: data?.pagination?.TotalPages,
                    onChange: (page) => setValue("page", page),
                  }}
                  columns={columns}
                  data={data?.result.items}
                  isAddUserList={false}
                  divClass="table-responsive table-card mb-1 "
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light text-muted"
                />
              ) : (
                <Spinner />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Employees;
