import React, { useState } from "react";
import { EmployeesPost } from "../../../api";
import {
  Button,
  Form,
  Spinner,
  Label,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Input as BootstrapInput } from "reactstrap";
import useHookForm from "../../../hooks/useHookForm";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPositionQuery } from "../../../queries";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const values = {
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  middleName: "",
  birthDate: "",
  positionId: null,
  state: null,
};

const AddEmployees = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clients = useQueryClient();
  const schema = createSchema({
    phoneNumber: "phone",
    password: "auth_password",
    firstName: "name",
    lastName: "name",
    middleName: "name",
    birthDate: "birthday",
    positionId: "select",
    state: "select",
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useHookForm(values, schema);
  const { data } = useQuery({
    ...getPositionQuery(),
  });
  const onSubmit = handleSubmit(async (res) => {
    setLoading(true);
    const positionId = res.positionId.value;
    const state = res.state.value;
    const datas = {
      ...res,
      state: state,
      positionId: positionId,
    };
  
    try {
      const response = await EmployeesPost(datas, "POST");

      reset();
      toast(t("Employee added"), {
        type: "success",
        theme: "colored",
        position: "top-center",
      });
      navigate("/employees");
      clients.invalidateQueries({ queryKey: ["employees"] });
    } catch (error) {
      toast(error.data?.error?.errorMessage, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className="page-content">
      <ToastContainer />
      <Container>
        <div className="p-2 mt-4">
          <Form onSubmit={onSubmit}>
            <div className="d-flex gap-5 w-100">
              <div className="mb-3 w-50">
                <Input
                  name="firstName"
                  control={control}
                  errors={errors}
                  label={t("First name")}
                  inputProps={{
                    placeholder: t("First name"),
                  }}
                />
              </div>
              <div className="mb-3 w-50">
                <Input
                  name="lastName"
                  control={control}
                  errors={errors}
                  label={t("Last name")}
                  inputProps={{
                    placeholder: t("Last Name"),
                  }}
                />
              </div>
            </div>
            <div className="d-flex gap-5">
              <div className="mb-3 w-50">
                <Input
                  name="middleName"
                  control={control}
                  errors={errors}
                  label={t("Middle name")}
                  inputProps={{
                    placeholder: t("Middle name"),
                  }}
                />
              </div>
              <div className="mb-3 w-50">
                <Label for="birthDate">{t("Birth date")}</Label>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      className="form-control"
                      options={{ dateFormat: "d M, Y" }}
                      onChange={(date) => field.onChange(date[0])}
                      value={field.value}
                      placeholder={t("Birth date")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex gap-5">
              <div className="mb-3 w-50">
                <Label for="positionId">Position</Label>
                <Controller
                  name="positionId"
                  control={control}
                  className="bg-white"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={data?.result?.items?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      placeholder="Select Position"
                    />
                  )}
                />
                {errors.positionId && <span>This field is required</span>}
              </div>

              <div className="mb-3 w-50">
                <Label for="positionId">State</Label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { value: 0, label: t("Not active") },
                        { value: 1, label: t("Active") },
                      ]}
                      placeholder="Select Position"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex gap-5">
              <div className="mb-3 w-50">
                <Input
                  name="phoneNumber"
                  control={control}
                  errors={errors}
                  label={t("Phone number")}
                  inputProps={{
                    placeholder: t("Phone number"),
                  }}
                />
              </div>
              <div className="mb-3 w-50">
                <Input
                  name="password"
                  control={control}
                  errors={errors}
                  label={t("Password")}
                  inputProps={{
                    type: "password",
                    placeholder: t("Password"),
                  }}
                />
              </div>
            </div>
            <div className="mb-3 ">
              <Input
                name="confirmPassword"
                control={control}
                errors={errors}
                label={t("Confirm password")}
                inputProps={{
                  type: "password",
                  placeholder: t("Confirm password"),
                }}
              />
            </div>
            <div className="d-flex justify-content-end align-items-end w-100">
              <Button
                className="btn btn-primary d-flex align-items-center gap-2  "
                type="submit"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                {t("Save")}
                <i className="ri-check-line"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default AddEmployees;
