import request from "../utils/axios";

export const getOrders = (params) => {
  return request({ method: "GET", url: "/orders", params });
};

export const getOrdersId = (id) => {
  return request({ method: "GET", url: `/orders/${id}` });
};

export const getModeration = (params) => {
  return request({ method: "GET", url: "/v1/moderations", params });
};

export const getProducts = (params) => {
  return request({ method: "GET", url: "/v1/products", params });
};

export const getProductsId = (id) => {
  return request({ method: "GET", url: `/v1/products/${id}` });
};

export const ProductsPost = (data) => {
  return request({ method: "POST", url: "/v1/products", data });
};

export const ProductsPut = (data) => {
  return request({ method: "PUT", url: "/v1/products", data });
};

export const ProductsVisibility = (id, data) => {
  return request({
    method: "POST",
    url: `/v1/products/${id}/visibility`,
    data,
  });
};

export const ProductState = (id, data) => {
  return request({
    method: "POST",
    url: `/v1/products/${id}/state`,
    data,
  });
};

export const getOrganizations = (id) => {
  return request({ method: "GET", url: `/organizations/${id}` });
};

export const getProfile = (params) => {
  return request({ method: "GET", url: "/profile", params });
};

export const getModerationId = (id) => {
  return request({ method: "GET", url: `/v1/moderations/id?id=${id}` });
};

export const getContracts = (params) => {
  return request({ method: "GET", url: "/contracts", params });
};

export const getClients = (params) => {
  return request({ method: "GET", url: "/clients", params });
};

export const getClientsId = (id) => {
  return request({ method: "GET", url: `/clients/${id}` });
};

export const getEmployees = (params) => {
  return request({ method: "GET", url: "/employees", params });
};

export const EmployeesPost = (data, method) => {
  return request({ method, url: "/employees", data });
};

export const getEmployeesSingle = (id) => {
  return request({ method: "GET", url: `/employees/${id}` });
};

export const getPosition = (params) => {
  return request({ method: "GET", url: "/positions", params });
};

export const EmployeesDelete = (id) => {
  return request({ method: "DELETE", url: `/employees/${id}` });
};

export const getReferencesFormal = (params) => {
  return request({ method: "GET", url: "/references/formal-types", params });
};

export const OrganizationPut = (id, data) => {
  return request({ method: "PUT", url: `/organizations/${id}`, data });
};

export const OrganizationAddressPut = (id, data) => {
  return request({ method: "PUT", url: `/organizations/${id}/address`, data });
};

export const OrganizationsOwnerPut = (id, data) => {
  return request({ method: "PUT", url: `/organizations/${id}/owner`, data });
};

export const OrganizationsBankPut = (id, data) => {
  return request({ method: "PUT", url: `/organizations/${id}/bank`, data });
};

export const OrganizationsContactPut = (id, data) => {
  return request({ method: "PUT", url: `/organizations/${id}/contact`, data });
};

export const getRegions = (params) => {
  return request({ method: "GET", url: "/regions", params });
};

export const getDistricts = (id) => {
  return request({ method: "GET", url: `/regions/${id}/districts` });
};

export const uploadFile = (id, data) => {
  return request({
    method: "POST",
    url: `/organizations/${id}/files`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadFiles = (data) => {
  return request({
    method: "POST",
    url: `/v1/files`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getFileId = (id) => {
  return request({
    method: "GET",
    url: `/files/organizations/${id}`,
    responseType: "blob",
  });
};

export const getAttributesId = (id) => {
  return request({
    method: "GET",
    url: `/v1/attributes?categoryId=${id}`,
  });
};

export const getCategories = (params) => {
  return request({
    method: "GET",
    url: "/v1/categories",
    params,
  });
};
