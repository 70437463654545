import React from "react";
import { Controller } from "react-hook-form";
import { Button, Form, Label, Spinner } from "reactstrap";
import Select from "react-select";
import { Input } from "../../../../Components/atoms/input";
import createSchema from "../../../../helpers/createSchema";
import useHookForm from "../../../../hooks/useHookForm";
import { t } from "i18next";
import { getCategoriesQuery } from "../../../../queries";
import { useQuery } from "@tanstack/react-query";
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
};
const AddProductForm = ({ Submit, setValue, loading }) => {
  const schema = createSchema({
    nameru: "name",
    "nameuz-Latn-UZ": "name",
    "nameuz-Cyrl-UZ": "name",
    descriptionru: "name",
    "descriptionuz-Latn-UZ": "name",
    "descriptionuz-Cyrl-UZ": "name",
  }); //validatsiya
  const {
    control,
    watch,
    handleSubmit,

    formState: { errors },
  } = useHookForm(values, schema);

  const onSubmit = handleSubmit((data) => {
    setValue("categoryId", data.categoryId);
    setValue("nameru", data.nameru);
    setValue("nameuz-Latn-UZ", data["nameuz-Latn-UZ"]);
    setValue("nameuz-Cyrl-UZ", data["nameuz-Cyrl-UZ"]);
    setValue("descriptionru", data.descriptionru);
    setValue("descriptionuz-Latn-UZ", data["descriptionuz-Latn-UZ"]);
    setValue("descriptionuz-Cyrl-UZ", data["descriptionuz-Cyrl-UZ"]);
    Submit();
  }); //input valuesini textga yuborish 

  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const selectedCategoryId = watch("parentCategoryId");
  const childCategories = selectedCategoryId
    ? categories?.item?.find(
        (category) => category.id === selectedCategoryId.value
      )?.childs || []
    : [];

  return (
    <div>
      <Form className="d-flex flex-column gap-3 mt-2">
        <div>
          <Label>{t("product category")}</Label>
          <Controller
            name="parentCategoryId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={categories?.item?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            )}
          />
        </div>
        {selectedCategoryId && (
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={childCategories.map((childCategory) => ({
                  value: childCategory.id,
                  label: childCategory.name,
                }))}
              />
            )}
          />
        )}
        <div>
          <Label>{t("name_ru")}</Label>
          <Input
            control={control}
            errors={errors}
            name="nameru"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>
        <div>
          <Label>{t("name_cyrl")}</Label>
          <Input
            control={control}
            errors={errors}
            name="nameuz-Cyrl-UZ"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>
        <div>
          <Label>{t("name_lat")}</Label>
          <Input
            control={control}
            errors={errors}
            name="nameuz-Latn-UZ"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>

        <div>
          <Label>{t("description_ru")}</Label>
          <Input
            control={control}
            errors={errors}
            name="descriptionru"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>
        <div>
          <Label>{t("description_cyrl")}</Label>
          <Input
            control={control}
            errors={errors}
            name="descriptionuz-Cyrl-UZ"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>
        <div>
          <Label>{t("description_lat")}</Label>
          <Input
            control={control}
            errors={errors}
            name="descriptionuz-Latn-UZ"
            inputProps={{
              placeholder: t("Enter the title"),
            }}
          />
        </div>

        <div className="d-flex justify-content-end ">
          <Button onClick={handleSubmit(onSubmit)}>
            {loading ? <Spinner size={"sm"} /> : t("Next")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProductForm;
