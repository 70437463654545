import React from "react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getProductsQuery } from "../../../queries/index";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
const schema = createSchema({});
const values = {
  page: 1,
  size: 15,
};

const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getProductsQuery({
      ...watch(),
    }),
  });

  // Column
  const columns = useMemo(() => [
    {
      Header: t("Product name"),
      accessor: "name",
      filterable: false,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/products/${row.original.id}`}
            className="fw-medium link-primary"
          >
            {row.original.name}
          </Link>
        );
      },
    },
    {
      Header: t("Product category"),
      accessor: (row) => row?.category?.name,
      filterable: false,
    },
    {
      Header: t("Price"),
      accessor: (row) =>
        row?.variations && row.variations[0]
          ? row.variations[0].originPrice
          : "",
      filterable: false,
    },

    {
      Header: t("Visible"),
      accessor: "isVisible",
      filterable: false,
      Cell: ({ value }) => {
        return value === false ? (
          <span className="badge text-uppercase bg-danger ">не видно</span>
        ) : (
          <span className="badge text-uppercase bg-success">видно</span>
        );
      },
    },

    {
      Header: t("Status"),
      accessor: "state",
      filterable: false,
      Cell: ({ value }) => {
        switch (value) {
          case 0:
            return (
              <span className="badge text-uppercase bg-danger ">
                {t("Not active")}
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase bg-success">
                {t("Active")}
              </span>
            );
        }
      },
    },
    // {
    //   Header: t("status"),
    //   accessor: "moderationStatus",
    //   Cell: ({ value }) => {
    //     switch (value) {
    //       case "OnModeration":
    //         return (
    //           <span className="badge text-uppercase badge-soft-danger">
    //             OnModeration
    //           </span>
    //         );
    //       case "Confirmed":
    //         return (
    //           <span className="badge text-uppercase badge-soft-success">
    //             Confirmed
    //           </span>
    //         );
    //       case "Updated":
    //         return (
    //           <span className="badge text-uppercase badge-soft-warning">
    //             Updated
    //           </span>
    //         );
    //       case "Rejected":
    //         return (
    //           <span className="badge text-uppercase badge-soft-info">
    //             Rejected
    //           </span>
    //         );
    //       default:
    //         return null;
    //     }
    //   },
    // },

    {
      Header: t("Actions"),
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item">
              <Button
                className="btn-warning display-4"
                onClick={() => navigate(`/products/edit/${value}`)}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
            </li>
          </ul>
        );
      },
    },
  ]);
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
              <Button
                onClick={() => navigate("/products/add")}
                className="d-flex gap-1 align-items-center"
              >
                {t("Add product")}
                <i className=" ri-add-line fs-4"></i>
              </Button>
            </div>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <TableContainer
                      pagination={{
                        currentPage: watch("page"),
                        totalPages: data?.pagination?.TotalPages,
                        onChange: (page) => setValue("page", page),
                      }}
                      columns={columns}
                      data={data?.item}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      isGlobalSearch={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
