import React from "react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getModerationsQuery } from "../../../queries/index";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
const schema = createSchema({});
const values = {
  page: 1,
  size: 15,
};

const ProductsModerate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getModerationsQuery({
      ...watch(),
    }),
  });
  const ProductAddPage = () => {
    navigate("/products/add");
  };
  const columns = useMemo(
    () => [
      {
        Header: t("Product name"),
        accessor: "name",
        filterable: false,
        Cell: ({ row }) => (
          <Link to={`/products-moderate/${row.original.variations[0].id}`}>
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: t("Product category"),
        accessor: (row) => row.category?.name,
        filterable: false,
      },
      {
        Header: t("Price"),
        accessor: (row) => row.variations[0].originPrice,
        filterable: false,
      },

      {
        Header: t("Visible"),
        accessor: "isVisible",
        filterable: false,
        Cell: ({ value }) => {
          return value === false ? (
            <span className="badge text-uppercase bg-danger ">
              {t("Not visible")}
            </span>
          ) : (
            <span className="badge text-uppercase bg-success">
              {t("Visible")}
            </span>
          );
        },
      },

      {
        Header: t("status"),
        accessor: "state",
        Cell: ({ value }) => {
          switch (value) {
            case 0:
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {t("Not active")}
                </span>
              );

            default:
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {t("Active")}
                </span>
              );
          }
        },
      },
    ],
    []
  );
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
              <Button
                onClick={() => navigate("/products/add")}
                className="d-flex gap-1 align-items-center"
              >
                {t("Add product")}
                <i className=" ri-add-line fs-4"></i>
              </Button>
            </div>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <TableContainer
                      pagination={{
                        currentPage: watch("page"),
                        totalPages: data?.pagination?.TotalPages,
                        onChange: (page) => setValue("page", page),
                      }}
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      SearchPlaceholder={"hello"}
                      isGlobalSearch={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductsModerate;
