import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Input as ReactInput,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getProfileQuery,
  getReferenceFormalQuery,
  getRegionsQuery,
} from "../../../queries";
import {
  OrganizationAddressPut,
  OrganizationPut,
  OrganizationsContactPut,
  OrganizationsOwnerPut,
  getDistricts,
  getFileId,
  getOrganizations,
  uploadFile,
} from "../../../api";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import "react-toastify/dist/ReactToastify.css";
import { prettify } from "../ui/prettify";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fileExtensionsTypeObj } from "../Components/file-format/format";

const values = {
  organizationName: "",
  organizationTin: "",
  productSaleTypes: null,
  type: null,
  vatNumber: "",
  id: null,
  address: "",
  districtId: null,
  regionId: null,
  serviceRegionsIds: [],
  ownerFullName: "",
  ownerPinfl: "",
  accountantFullName: "",
  accountantPinfl: "",
  email: "",
  phone: "",
  description: "",
  files: null,
};

const OrganizationEdit = () => {
  const { t } = useTranslation();
  const schema = createSchema({
    organizationName: "name",
    type: "select",
    organizationTin: "number",
    vatNumber: "number",
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,

    formState: { errors },
  } = useHookForm(values, schema);
  const [activeTab, setActiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [selectedServiceRegions, setSelectedServiceRegions] = useState([]);
  const [saleTypeState, setSaleTypeState] = useState([]);
  const navigate = useNavigate();
  const clients = useQueryClient();
  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const { data: profileData } = useQuery({
    ...getProfileQuery(),
  });

  const profileId = profileData?.result?.organizationId;
  const { data, isLoading } = useQuery({
    queryKey: ["organizations", profileId],
    queryFn: () => getOrganizations(profileId),
    enabled: !!profileId,
  });

  const { data: referenceFormal, isLoading: referenceFormalLoading } = useQuery(
    {
      ...getReferenceFormalQuery(),
    }
  );
  const { data: regions, isLoading: regionLoading } = useQuery({
    ...getRegionsQuery(),
  });

  const [regionId, setRegionId] = useState(
    regions?.result?.regions?.id || null
  );

  // const fileExtensionsTypeObj = {
  //   ["image/png"]: "png",
  //   ["image/jpeg"]: "jpeg",
  //   ["image/jpg"]: "jpg",
  //   ["image/gif"]: "gif",
  //   ["application/pdf"]: "pdf",
  //   ["application/zip"]: "zip",
  //   ["video/mp4"]: "mp4",
  //   ["application/x-rar-compressed"]: "rar",
  //   ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]:
  //     "docx",
  // };

  useEffect(() => {
    setRegionId(data?.result?.address.region?.id);
  }, [data]);

  const { data: districts, isLoading: districtsLoading } = useQuery({
    queryKey: ["districts", regionId],
    queryFn: () => getDistricts(regionId),
    enabled: !!regionId,
  });
  const filesId = data?.result?.organizationFiles?.find(
    (item) => item.type === 1
  )?.id;
  const { data: file, isLoading: fileLoading } = useQuery({
    queryKey: ["fileId", filesId],
    queryFn: () => getFileId(filesId),
    enabled: !!filesId,
  });

  const GetId = () => {
    if (file) {
      const fileType = file.type;
      const fileExtension = fileExtensionsTypeObj[fileType];
      const date = new Date();
      const fileName = `${Document.name}_${date
        .toLocaleDateString()
        .replace(/[:.-]/g, "_")}.${fileExtension}`;

      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };
  const handleServiceRegionChange = (regionId) => {
    setSelectedServiceRegions((prevSelectedRegions) =>
      prevSelectedRegions.includes(regionId)
        ? prevSelectedRegions.filter((id) => id !== regionId)
        : [...prevSelectedRegions, regionId]
    );
  };

  const getInitialSaleTypeState = (productSaleTypes) => {
    const saleTypes = [];
    if (productSaleTypes & 1) saleTypes.push(1);
    if (productSaleTypes & 2) saleTypes.push(2);
    if (productSaleTypes & 4) saleTypes.push(4);
    if (productSaleTypes & 8) saleTypes.push(8);
    return saleTypes;
  };
  useEffect(() => {
    if (data) {
      reset({
        productSaleTypes: data?.result.productSaleTypes,
        organizationName: data.result.information.organizationName,
        organizationTin: prettify(data.result.information.organizationTin),
        vatNumber: prettify(data.result.information.vatNumber),
        ownerFullName: data?.result?.owner?.ownerFullName,
        type: data.result.formalType
          ? {
              value: data.result.formalType.id,
              label: data.result.formalType.name,
            }
          : null,
        address: data?.result?.address?.address,
        servingBankName: data?.result?.bank?.servingBankName,
        mfo: data?.result?.bank?.mfo,
        accountNumber: data?.result.bank?.accountNumber,
        oked: data?.result?.bank?.oked,
        email: data?.result?.contact?.email,
        phone: data?.result?.contact?.phone,
        description: data?.result?.contact?.description,
        regionId: data?.result?.address?.region
          ? {
              value: data?.result?.address?.region?.id,
              label: data?.result?.address?.region.name,
            }
          : null,
        districtId: data?.result?.address?.district
          ? {
              value: data?.result?.address?.district?.id,
              label: data?.result?.address?.district?.name,
            }
          : null,
        files: data?.result.organizationFiles
          ? data.result.organizationFiles.find((item) => item.type === 1)?.id
          : null,
        serviceRegionsIds: data?.result?.address?.serviceRegion?.map(
          (item) => item.id
        ),
      });

      const initialServiceRegions =
        data?.result?.address?.serviceRegion?.map((item) => item.id) || [];
      setSelectedServiceRegions(initialServiceRegions);
      setSaleTypeState(getInitialSaleTypeState(data?.result.productSaleTypes));
    }
  }, [data, reset, referenceFormal]);

  const RegionChange = (regionOption) => {
    setRegionId(regionOption.value);
    setValue("regionId", regionOption);
    setValue("districtId", null);
  };

  const DistrictChange = (districtOption) => {
    setValue("districtId", districtOption);
  };
  const handleSaleTypeChange = (type) => {
    setSaleTypeState((prevState) => {
      if (prevState.includes(type)) {
        return prevState.filter((t) => t !== type);
      } else {
        return [...prevState, type];
      }
    });
  };

  const calculateSaleType = () => {
    return saleTypeState.reduce((acc, type) => acc | type, 0);
  };
  const Submit = async (res) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("type", 1);
      formData.append("files", imageFile);
    }
    const id = profileId;
    const type = res.type !== null ? res.type.value : null;
    setLoading(true);
    const datas = {
      id,
      type,
      organizationName: res.organizationName,
      organizationTin: res.organizationTin,
      vatNumber: res.vatNumber,
      productSaleTypes: calculateSaleType(),
    };
    const districtId = res.districtId ? res.districtId.value : null;
    const regionId = res.regionId ? res.regionId.value : null;
    const addressData = {
      id: profileId,
      address: res.address,
      districtId: districtId,
      regionId: regionId,
      serviceRegionsIds: selectedServiceRegions,
    };

    const contactData = {
      description: res.description,
      email: res.email,
      phone: res.phone,
    };
    const accountantFullName = data?.result?.owner?.accountantFullName;
    const accountantPinfl = data?.result?.owner?.accountantPinfl;
    const ownerPinfl = data?.result?.owner?.ownerPinfl;
    const ownerData = {
      accountantFullName: accountantFullName,
      accountantPinfl: accountantPinfl,
      id: profileId,
      ownerFullName: res.ownerFullName,
      ownerPinfl: ownerPinfl,
    };

    try {
      await OrganizationPut(profileId, datas);
      await OrganizationAddressPut(profileId, addressData);
      await OrganizationsContactPut(profileId, contactData);
      await OrganizationsOwnerPut(profileId, ownerData);
      if (imageFile) {
        await uploadFile(profileId, formData);
      }
      // toggleTab(activeTab + 1, 100);
      navigate("/profile");
      clients.invalidateQueries(["profile"]);
    } catch (error) {
      toast(error.data?.errorMessage, {
        theme: "colored",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      {isLoading ||
      referenceFormalLoading ||
      regionLoading ||
      districtsLoading ||
      fileLoading ? (
        <Spinner />
      ) : (
        <Col className="w-75">
          <Card>
            <CardBody>
              <div className="text-center pt-3 pb-4 mb-1">
                <h2 style={{ fontWeight: 600 }}>
                  {t("Merchant identification")}
                </h2>
                <p>
                  {t("Fill out all the fields and click the «Send» button")}
                </p>
              </div>

              <div className="progress-nav mb-4">
                <Progress value={progressbarvalue} style={{ height: "1px" }} />
                <Nav
                  className="nav-pills progress-bar-tab custom-nav"
                  role="tablist"
                >
                  <Button
                    className={classnames(
                      {
                        active: activeTab === 1,
                        done: activeTab <= 4 && activeTab >= 0,
                      },
                      "rounded-pill"
                    )}
                    // onClick={() => {
                    //   toggleTab(1, 0);
                    // }}
                    tag="button"
                  >
                    1
                  </Button>
                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 2,
                          done: activeTab <= 4 && activeTab > 1,
                        },
                        "rounded-pill"
                      )}
                      // onClick={() => {
                      //   toggleTab(2, 100);
                      // }}
                      tag="button"
                    >
                      2
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Form
                    onSubmit={handleSubmit(Submit)}
                    className="d-flex flex-column gap-3"
                  >
                    <h4 style={{ fontWeight: 600 }}>Основные данные</h4>
                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Legal name of the organization")}
                        </Label>
                        <Input
                          control={control}
                          errors={errors}
                          defaultValue={
                            data?.result?.information?.organizationName
                          }
                          name="organizationName"
                        />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Type of organization")}
                        </Label>
                        <Controller
                          name="type"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={referenceFormal?.result?.items?.map(
                                (item) => ({
                                  value: item.id,
                                  label: item.name,
                                })
                              )}
                              placeholder="Select Position"
                              value={field.value}
                              onChange={(selectedOption) =>
                                setValue("type", selectedOption)
                              }
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Full name of the manager")}
                        </Label>
                        <Input
                          control={control}
                          errors={errors}
                          name="ownerFullName"
                        />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("ИНН юр. лица")}
                        </Label>
                        <Input
                          control={control}
                          errors={errors}
                          defaultValue={
                            data?.result?.information?.organizationTin
                          }
                          name="organizationTin"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("VAT payer number")}
                        </Label>
                        <Input
                          control={control}
                          errors={errors}
                          defaultValue={data?.result?.information?.vatNumber}
                          name="vatNumber"
                        />
                      </Col>
                      <Col md="6" className="d-flex align-items-center">
                        <div>
                          <Label style={{ fontWeight: 550 }}>
                            {t(
                              "Certificate of registration of the organization"
                            )}
                          </Label>
                          <ReactInput
                            className="form-control form-control-md"
                            id="formSizeSmall"
                            type="file"
                            name="files"
                            onChange={(e) => setImageFile(e.target.files[0])}
                          />
                          {data?.result?.organizationFiles?.find(
                            (item) => item.type === 1
                          ) && (
                            <p>
                              {
                                data.result.organizationFiles.find(
                                  (item) => item.type === 1
                                ).id
                              }
                            </p>
                          )}
                        </div>

                        <Button size="md" color="link" onClick={GetId}>
                          Download
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Sale type")}
                        </Label>
                        <div className="d-flex flex-column">
                          <div className="form-check">
                            <ReactInput
                              type="checkbox"
                              id="compensation"
                              value={1}
                              onChange={() => handleSaleTypeChange(2)}
                              checked={saleTypeState.includes(2)}
                            />
                            <Label htmlFor="compensation">
                              {t("Compensation")}
                            </Label>
                          </div>

                          <div className="form-check">
                            <ReactInput
                              type="checkbox"
                              id="installment"
                              value={2}
                              onChange={() => handleSaleTypeChange(1)}
                              checked={saleTypeState.includes(1)}
                            />
                            <Label htmlFor="installment">
                              {t("Installment")}
                            </Label>
                          </div>

                          <div className="form-check">
                            <ReactInput
                              type="checkbox"
                              id="paid"
                              value={4}
                              onChange={() => handleSaleTypeChange(4)}
                              checked={saleTypeState.includes(4)}
                            />
                            <Label htmlFor="paid">{t("Sale")}</Label>
                          </div>
                          <div className="form-check">
                            <ReactInput
                              type="checkbox"
                              id="bmt"
                              value={4}
                              onChange={() => handleSaleTypeChange(8)}
                              checked={saleTypeState.includes(8)}
                            />
                            <Label htmlFor="bmt">BMT</Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <h4 style={{ fontWeight: 600 }}>{t("Address")}</h4>
                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>{t("City")}</Label>
                        <Controller
                          control={control}
                          name="regionId"
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={regions?.result?.regions?.map(
                                (item) => ({
                                  value: item.id,
                                  label: item.name,
                                })
                              )}
                              onChange={RegionChange}
                              value={field.value}
                            />
                          )}
                        />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("District")}
                        </Label>
                        <Controller
                          control={control}
                          name="districtId"
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={districts?.result?.districts?.map(
                                (item) => ({
                                  value: item.id,
                                  label: item.name,
                                })
                              )}
                              onChange={DistrictChange}
                              value={field.value}
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Address")}
                        </Label>
                        <Controller
                          control={control}
                          name="address"
                          render={({ field }) => (
                            <textarea
                              {...field}
                              className="form-control"
                              rows={5}
                              value={field.value}
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Label style={{ fontWeight: 550 }}>
                        {t(
                          "The presence of branches and agents in the regions"
                        )}
                      </Label>
                      {regions?.result?.regions?.map((region, index) => (
                        <Col xs="6" md="3" key={region.id}>
                          <div className="d-flex gap-2 align-items-center form-check form-check-secondary">
                            <input
                              type="checkbox"
                              className="form-check-input form-check-secondary "
                              checked={selectedServiceRegions.includes(
                                region.id
                              )}
                              onChange={() =>
                                handleServiceRegionChange(region.id)
                              }
                            />
                            <span>{region.name}</span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <h4 style={{ fontWeight: 600 }}>{t("Bank details")}</h4>
                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Servicing bank")}
                        </Label>
                        <Input
                          errors={errors}
                          control={control}
                          name="servingBankName"
                        />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("МФО банка")}
                        </Label>
                        <Input errors={errors} control={control} name="mfo" />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Payment account")}
                        </Label>
                        <Input
                          errors={errors}
                          control={control}
                          name="accountNumber"
                          maxLength={20}
                        />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>{t("ОКЭД")}</Label>
                        <Input errors={errors} control={control} name="oked" />
                      </Col>
                    </Row>
                    <h4 style={{ fontWeight: 600 }}>
                      {t("Additional information")}
                    </h4>
                    <Row>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>E-mail</Label>
                        <Input errors={errors} name="email" control={control} />
                      </Col>
                      <Col md="6">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Contact number")}
                        </Label>
                        <Input errors={errors} name="phone" control={control} />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <Label style={{ fontWeight: 550 }}>
                          {t("Description")}
                        </Label>
                        <Controller
                          control={control}
                          name="description"
                          render={({ field }) => (
                            <textarea
                              {...field}
                              className="form-control"
                              rows={5}
                              value={field.value}
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex align-items-start gap-3 mt-4">
                      <Button
                        type="submit"
                        className="btn btn-primary btn-label right ms-auto nexttab"
                      >
                        {loading ? (
                          <div className="d-flex align-items-center gap-3">
                            <Spinner size={"sm"} />
                            <div>
                              <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                              {t("Save")}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Save")}
                          </div>
                        )}
                      </Button>
                    </div>
                  </Form>
                </TabPane>
                <TabPane tabId={2}>
                  {/* <AddressForm
                    profileId={profileId}
                    data={data}
                    toggleTab={toggleTab}
                    activeTab={activeTab}
                  /> */}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default OrganizationEdit;
