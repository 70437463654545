import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  TabContent,
  TabPane,
  Input as ReactInput,
} from "reactstrap";
import { useFieldArray } from "react-hook-form";
import useHookForm from "../../../hooks/useHookForm";
import { ProductsPost, getAttributesId, uploadFiles } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategoriesQuery, getProfileQuery } from "../../../queries";
import classnames from "classnames";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import AddProductForm from "./Components/add-product-form";

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
  ],
  files: [],
  saleType: [],
};

const AddProducts = () => {
  const schema = createSchema({});
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useHookForm(values, schema);

  const [activeTab, setactiveTab] = useState(1);
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileInputs, setFileInputs] = useState([0]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [saleTypeState, setSaleTypeState] = useState([]);
  const client = useQueryClient();
  const navigate = useNavigate();

  const handleFileUpload = (acceptedFiles, index) => {
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles[index] = acceptedFiles[0];
    setImageFiles(updatedImageFiles);

    const updatedPreviews = [...filePreviews];
    updatedPreviews[index] = URL.createObjectURL(acceptedFiles[0]);
    setFilePreviews(updatedPreviews);

    if (index === fileInputs.length - 1) {
      setFileInputs([...fileInputs, fileInputs.length]);
    }
  };

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  function toggleVerticalTab(tab) {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }

  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });
  const handleSaleTypeChange = (type) => {
    setSaleTypeState((prevState) => {
      if (prevState.includes(type)) {
        return prevState.filter((t) => t !== type);
      } else {
        return [...prevState, type];
      }
    });
  };

  const calculateSaleType = () => {
    return saleTypeState.reduce((acc, type) => acc + type, 0);
  };
  const selectedCategoryId = watch("parentCategoryId");
  const childCategories = selectedCategoryId
    ? categories?.item?.find(
        (category) => category.id === selectedCategoryId.value
      )?.childs || []
    : [];

  const Submit = async (data) => {
    setLoading(true);
    const attributeId = watch("categoryId")?.value;
    try {
      const res = await getAttributesId(attributeId);
      toggleTab(activeTab + 1, 100);
      const arr = res.item.map((item) => ({
        ...item,
        attributeId: item.id,
        value: "",
        valueTranslations: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
      }));
      setValue("attrs", arr);
      client.invalidateQueries({ queryKey: ["products"] });
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const ProductSubmit = async (data) => {
    setLoading(true);
    let index = 1;
    const uploadedFiles = [];

    for (const file of imageFiles) {
      const formData = new FormData();
      formData.append("files", file);

      const resImage = await uploadFiles(formData);
      const imageObj = resImage?.[0];

      uploadedFiles.push({
        order: index,
        url: imageObj?.url,
        fileId: imageObj?.id,
        variationId: null,
        productId: null,
      });

      index++;
    }

    const names = Object.keys(data.name).map((languageCode) => ({
      languageCode,
      text: watch(`name${languageCode}`),
    })); //namelarni togri formatda shakllantirib beradi

    const descriptions = Object.keys(data.description).map((languageCode) => ({
      languageCode,
      text: watch(`description${languageCode}`),
    }));

    const variations = [
      {
        count: 1000,
        compensationOnly: false,
        productId: null,
        attributeValues: data?.attrs?.map((attr) => ({
          attributeId: attr.attributeId,
          value: attr.value,
          productId: null,
          valueTranslations: [
            { languageCode: "ru", text: attr.valueTranslations.ru },
            {
              languageCode: "uz-Latn-UZ",
              text: attr.valueTranslations["uz-Latn-UZ"],
            },
            {
              languageCode: "uz-Cyrl-UZ",
              text: attr.valueTranslations["uz-Cyrl-UZ"],
            },
          ],
          variationId: null,
        })),
        prices: data?.prices.map((price) => ({
          currencyId: 1,
          value: price.value,
          variationId: null,
          type: price.type,
        })),
        files: uploadedFiles,
        saleType: calculateSaleType(),
      },
    ];
    const attributeId = data?.categoryId?.value;
    const datas = {
      name: names,
      description: descriptions,
      brandId: 1,
      categoryId: attributeId,
      organizationId: profile?.result?.organizationId,
      variations: variations,
    };

    try {
      await ProductsPost(datas);
      toggleTab(activeTab + 1, 100);
      client.invalidateQueries({ queryKey: ["moderations"] });
      navigate("/products-moderate");
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const { fields } = useFieldArray({
    control,
    name: "attrs",
  });

  return (
    <div className="page-content">
      <Col className="w-50">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Progress Nav Steps</h4>
          </CardHeader>
          <CardBody>
            <div className="text-center pt-3 pb-4 mb-1">
              <h5>Sign up Your Account</h5>
            </div>

            <div className="progress-nav mb4">
              <Progress value={progressbarvalue} style={{ height: "1px" }} />

              <Nav
                className="nav-pills progress-bar-tab custom-nav"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    id="pills-gen-info-tab"
                    className={classnames(
                      {
                        active: activeTab === 1,
                        done: activeTab <= 4 && activeTab >= 0,
                      },
                      "rounded-pill"
                    )}
                    tag="button"
                  >
                    1
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    id="pills-profile-tab"
                    className={classnames(
                      {
                        active: activeTab === 2,
                        done: activeTab <= 4 && activeTab > 1,
                      },
                      "rounded-pill"
                    )}
                    tag="button"
                  >
                    2
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent
              activeTab={activeTab}
              className="form-steps-tab-content"
            >
              <TabPane tabId={1}>
                <AddProductForm
                  loading={loading}
                  Submit={Submit} //backendga ketayotga function
                  setValue={setValue}
                />
                {/* birinchi form */}
              </TabPane>

              <TabPane tabId={2}>
                <Form>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="w-100">
                      <Label>
                        {t("Price")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        control={control}
                        name="prices[0].value"
                        errors={errors}
                      />
                    </div>
                    <div className="w-100">
                      <Label>
                        {t("Including VAT")}{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        control={control}
                        name="prices[1].value"
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="sale-type">{t("Transaction type")}</Label>
                    <div className="d-flex flex-column">
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-available"
                          value={1}
                          onChange={() => handleSaleTypeChange(2)}
                          checked={saleTypeState.includes(2)}
                        />
                        <Label htmlFor="order-available">Компенсация</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="pre-order"
                          value={2}
                          onChange={() => handleSaleTypeChange(1)}
                          checked={saleTypeState.includes(1)}
                        />
                        <Label htmlFor="pre-order">Рассрочка</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-in-2-4-weeks"
                          value={4}
                          onChange={() => handleSaleTypeChange(4)}
                          checked={saleTypeState.includes(4)}
                        />
                        <Label htmlFor="order-in-2-4-weeks">Продажа</Label>
                      </div>
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-in-2-4-weeks"
                          value={4}
                          onChange={() => handleSaleTypeChange(8)}
                          checked={saleTypeState.includes(8)}
                        />
                        <Label htmlFor="order-in-2-4-weeks">ООН</Label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex gap-2 flex-wrap">
                    {fileInputs.map((input, index) => (
                      <div key={index} style={{ width: "300px" }}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleFileUpload(acceptedFiles, index)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                height: "150px",
                                border: "2px dashed #cccccc",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {!filePreviews[index] && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className="bx bx-image-add display-4"></i>
                                </div>
                              )}
                              {filePreviews[index] && (
                                <img
                                  src={filePreviews[index]}
                                  alt={`Preview ${index}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex flex-column gap-3">
                    {fields.map((item, idx) => (
                      <div key={idx}>
                        {item.filter ? (
                          item.filter.filterType === "Range" ||
                          item.filter.filterType === "Price" ? (
                            <div>
                              <div className="d-flex flex-column ">
                                <Label style={{ fontWeight: 550 }}>
                                  {item.name}
                                </Label>
                                <span style={{ color: "gray" }}>
                                  {item?.filter?.values}
                                </span>
                              </div>
                              <Input
                                control={control}
                                name={`attrs[${idx}].value`}
                                errors={errors}
                              />
                            </div>
                          ) : item.filter.filterType === "SingleSelect" ||
                            item.filter.filterType === "MultipleSelect" ? (
                            <div>
                              <Label style={{ fontWeight: 550 }}>
                                {item.name}
                              </Label>
                              {item.filter.values
                                .split(",")
                                .map((value, valueIdx) => (
                                  <div key={valueIdx} className="form-check">
                                    <ReactInput
                                      name={`attrs[${idx}].value`}
                                      value={value}
                                      type={
                                        item.filter.filterType ===
                                        "SingleSelect"
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      checked={
                                        watch(`attrs[${idx}].value`) === value
                                      }
                                      onChange={() =>
                                        setValue(`attrs[${idx}].value`, value)
                                      }
                                      className="form-check-input"
                                      id={value}
                                    />
                                    <Label
                                      htmlFor={value}
                                      className="form-check-label"
                                    >
                                      {value === "0"
                                        ? t("Yes")
                                        : value === "1"
                                        ? t("No")
                                        : value}
                                    </Label>
                                  </div>
                                ))}
                            </div>
                          ) : null
                        ) : !item.isValueTranslated ? (
                          <div>
                            <Label>{item.name}</Label>
                            <Input
                              control={control}
                              name={`attrs[${idx}].value`}
                              errors={errors}
                            />
                          </div>
                        ) : (
                          item.isValueTranslated && (
                            <div className="d-flex flex-column gap-3">
                              <Label>{item.name}</Label>
                              <Input
                                control={control}
                                name={`attrs[${idx}].valueTranslations.ru`}
                                inputProps={{
                                  placeholder: t("in russian"),
                                }}
                                errors={errors}
                              />
                              <Input
                                control={control}
                                name={`attrs[${idx}].valueTranslations.uz-Latn-UZ`}
                                inputProps={{
                                  placeholder: t("in uzbek(kyr)"),
                                }}
                                errors={errors}
                              />
                              <Input
                                control={control}
                                name={`attrs[${idx}].valueTranslations.uz-Cyrl-UZ`}
                                inputProps={{
                                  placeholder: t("in uzbek(lat)"),
                                }}
                                errors={errors}
                              />
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center gap-2 justify-content-end mt-2">
                    <Button
                      outline
                      onClick={() => {
                        toggleTab(1, 0);
                      }}
                    >
                      {t("Back")}
                    </Button>
                    <Button onClick={handleSubmit(ProductSubmit)}>
                      {t("Save")}
                    </Button>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default AddProducts;
