import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input as ReactInput, Label } from "reactstrap";
import { t } from "i18next";
import StateSelect from "../Components/StateSelect";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { useFieldArray } from "react-hook-form";
import {
  ProductsPut,
  ProductsVisibility,
  getAttributesId,
  getProductsId,
  uploadFiles,
} from "../../../api";
import Dropzone from "react-dropzone";
import Visibility from "../Components/Visibility";

const values = {
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
  ],
  isVisible: null,
  files: [],
};

const ProductsEdit = () => {
  const schema = createSchema({});
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [imageFiles, setImageFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [fileInputs, setFileInputs] = useState([]);
  const navigate = useNavigate();
  const { handleSubmit, setValue, control, watch } = useHookForm(
    values,
    schema
  );

  const { data } = useQuery({
    queryKey: ["productsEdit", id],
    queryFn: () => getProductsId(id),
  });
  const products = data?.result;
  const categoryId = data?.result?.categoryId;
  const { data: attributes } = useQuery({
    queryKey: ["categoryId", categoryId],
    queryFn: () => getAttributesId(categoryId),
    enabled: !!categoryId,
  });

  const handleFileUpload = (acceptedFiles, index) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setFilePreviews((prevPreviews) => [
      ...prevPreviews,
      ...newFiles.map((f) => f.preview),
    ]);
    setFileInputs((prevInputs) => [...prevInputs, index]);
  };

  const allPrice = products?.variations?.[0].prices?.filter(
    (item) => item.type !== "Price" && item.type !== "Vat"
  );
  function getLastPartOfUrl(url) {
    if (url.length > 36) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return url;
    }
  }
  async function Images() {
    try {
      const uploadedFiles = [];
      for (const file of imageFiles) {
        const fd = new FormData();
        fd.append("files", file.file);
        const resImage = await uploadFiles(fd);
        const imageObj = resImage?.[0];

        uploadedFiles.push({
          order: uploadedFiles.length + 1,
          url: imageObj?.url,
          fileId: imageObj?.id,
          variationId: products?.variations?.[0]?.id,
          productId: products?.id,
          isVisible: true,
        });
      }
      return uploadedFiles;
    } catch (error) {
      console.error("Error uploading images:", error);
      throw error;
    }
  }
  const Submit = async (res) => {
    const uploadedFiles = await Images();
    const descriptions = Object.keys(res.description).map((languageCode) => ({
      languageCode,
      text: res.description[languageCode],
    }));

    const variations = [
      {
        count: 1000,
        compensationOnly: false,
        productId: id,
        attributeValues: Object.keys(res?.attrs)?.map((attrId) => ({
          attributeId: parseInt(attrId),
          value: res?.attrs[attrId].value || "",
          productId: id,
          isVisible: true,
          valueTranslations: [
            {
              languageCode: "ru",
              text: res?.attrs[attrId].valueTranslations?.ru || "",
            },
            {
              languageCode: "uz-Latn-UZ",
              text: res?.attrs[attrId].valueTranslations?.["uz-Latn-UZ"] || "",
            },
            {
              languageCode: "uz-Cyrl-UZ",
              text: res?.attrs[attrId].valueTranslations?.["uz-Cyrl-UZ"] || "",
            },
          ],
          variationId: products?.variations?.[0]?.id,
        })),

        prices: [
          ...(res.prices?.map((item) => ({
            currencyId: 1,
            value: item.value,
            variationId: products?.variations?.[0]?.id,
            type: item.type,
          })) || []),
          ...(allPrice?.map((item) => ({
            currencyId: 1,
            value: item.value,
            variationId: products?.variations?.[0]?.id,
            type: item.type,
          })) || []),
        ],

        isVisible: products?.isVisible,
        files: uploadedFiles.concat(
          products.variations?.[0]?.files.map((item) => ({
            order: item.order,
            url: item.url,
            fileId: getLastPartOfUrl(item.url),
            variationId: item.variationId,
            productId: id,
            isVisible: true,
          })) || []
        ),
        saleType: 1,
      },
    ];

    const datas = {
      descriptions: descriptions,
      productId: id,
      variations: variations,
    };

    try {
      await Images();
      await ProductsPut(datas);
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    }
  };

  const getTextByLanguageCode = (descriptions, languageCode) => {
    const description = descriptions.find(
      (desc) => desc.languageCode === languageCode
    );
    return description ? description.text : "";
  };

  useEffect(() => {
    if (products) {
      const descriptions = products.descriptions;
      setValue("description.ru", getTextByLanguageCode(descriptions, "ru"));
      setValue(
        "description.uz-Cyrl-UZ",
        getTextByLanguageCode(descriptions, "uz-Cyrl-UZ")
      );
      setValue(
        "description.uz-Latn-UZ",
        getTextByLanguageCode(descriptions, "uz-Latn-UZ")
      );

      products.variations?.[0]?.attributeValues?.forEach((attrValue) => {
        setValue(`attrs.${attrValue.attributeId}.value`, attrValue.value);

        if (attrValue.valueTranslations) {
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.ru`,
            attrValue.valueTranslations.ru || ""
          );
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.uz-Latn-UZ`,
            attrValue.valueTranslations["uz-Latn-UZ"] || ""
          );
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.uz-Cyrl-UZ`,
            attrValue.valueTranslations["uz-Cyrl-UZ"] || ""
          );
        }
      });

      const existingFiles = products?.variations?.[0]?.files || [];
      const previews = existingFiles.map((file) => file.url);
      setFilePreviews(previews);
      setFileInputs([...Array(existingFiles.length + 1).keys()]);

      setValue(
        "prices[0].value",
        products?.variations?.[0]?.prices[0]?.value || ""
      );
      setValue(
        "prices[1].value",
        products?.variations?.[0]?.prices[1]?.value || ""
      );
    }
  }, [products, setValue]);

  return (
    <div className="page-content w-75 ">
      <div className="d-flex align-items-start gap-3">
        <i
          className="ri-arrow-left-line fs-3 cursor-pointer"
          onClick={() => navigate(-1)}
        ></i>
        <h2>{t("Editing a product")}</h2>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-3 flex-column">
          <div>
            <Label className="fw-semibold">{t("Product status")}</Label>
            <StateSelect id={id} data={data} />
          </div>
          <div>
            <Label className="fw-semibold">{t("Visible")}</Label>
            <Visibility id={id} data={data} />
          </div>
        </div>
        <div>
          <Label className="fw-semibold">{t("Product category")}</Label>
          <ReactInput
            className="form-control border-dashed p-3"
            value={data?.result?.category?.name}
            readOnly
          />
        </div>
        <div>
          <Label className="fw-semibold">{t("name_ru")}</Label>
          <ReactInput
            className="form-control border-dashed p-3"
            value={data?.result?.name}
            readOnly
          />
        </div>
      </div>

      <Form
        onSubmit={handleSubmit(Submit)}
        className="d-flex flex-column gap-3 mt-3"
      >
        <div>
          <Label className="fw-semibold">{t("description_ru")}</Label>
          <Input control={control} name="description.ru" />
        </div>
        <div>
          <Label className="fw-semibold">{t("description_cyrl")}</Label>
          <Input control={control} name="description.uz-Cyrl-UZ" />
        </div>
        <div>
          <Label className="fw-semibold">{t("description_lat")}</Label>
          <Input control={control} name="description.uz-Latn-UZ" />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <div className="w-100">
            <Label className="fw-semibold">
              {t("Price")}
              <span className="text-danger">*</span>
            </Label>
            <Input control={control} name="prices[0].value" />
          </div>
          <div className="w-100">
            <Label className="fw-semibold">
              {t("Including VAT")} <span className="text-danger">*</span>
            </Label>
            <Input control={control} name="prices[1].value" />
          </div>
        </div>
        <div className="d-flex gap-2 flex-wrap mt-3">
          {fileInputs.map((input, index) => (
            <div key={index} style={{ width: "300px", marginBottom: "20px" }}>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleFileUpload(acceptedFiles, index)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: "80px",
                      border: "2px dashed #cccccc",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {!filePreviews[index] && (
                      <div className="d-flex align-items-center justify-content-center">
                        <i className="bx bx-image-add display-4"></i>
                      </div>
                    )}
                    {filePreviews[index] && (
                      <img
                        src={filePreviews[index]}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column gap-3">
          {attributes?.item?.map((item, idx) => (
            <div key={idx}>
              {item.filter ? (
                item.filter.filterType === "Range" ||
                item.filter.filterType === "Price" ? (
                  <div>
                    <div className="d-flex flex-column ">
                      <Label style={{ fontWeight: 550 }}>{item.name}</Label>
                      <span style={{ color: "gray" }}>
                        {item?.filter?.values}
                      </span>
                    </div>
                    <Input control={control} name={`attrs.${item.id}.value`} />
                  </div>
                ) : item.filter.filterType === "SingleSelect" ||
                  item.filter.filterType === "MultipleSelect" ? (
                  <div>
                    <Label style={{ fontWeight: 550 }}>{item.name}</Label>
                    {item.filter.values.split(",").map((value, valueIdx) => (
                      <div key={valueIdx} className="form-check">
                        <ReactInput
                          name={`attrs.${item.id}.value`}
                          value={value}
                          type={
                            item.filter.filterType === "SingleSelect"
                              ? "radio"
                              : "checkbox"
                          }
                          checked={watch(`attrs.${item.id}.value`) === value}
                          onChange={() =>
                            setValue(`attrs.${item.id}.value`, value)
                          }
                          className="form-check-input"
                          id={value}
                        />
                        <Label htmlFor={value} className="form-check-label">
                          {value === "0" ? "Yes" : value === "1" ? "No" : value}
                        </Label>
                      </div>
                    ))}
                  </div>
                ) : null
              ) : !item.isValueTranslated ? (
                <div>
                  <Label>{item.name}</Label>
                  <Input control={control} name={`attrs.${item.id}.value`} />
                </div>
              ) : (
                item.isValueTranslated && (
                  <div className="d-flex flex-column gap-3">
                    <Label>{item.name}</Label>
                    <Input
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.ru`}
                      inputProps={{
                        placeholder: t("in russian"),
                      }}
                    />
                    <Input
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.uz-Latn-UZ`}
                      inputProps={{
                        placeholder: t("in uzbek(kyr)"),
                      }}
                    />
                    <Input
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.uz-Cyrl-UZ`}
                      inputProps={{
                        placeholder: t("in uzbek(lat)"),
                      }}
                    />
                  </div>
                )
              )}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-3 mb-2">
          <Button type="submit">{t("Save")}</Button>
        </div>
      </Form>
    </div>
  );
};

export default ProductsEdit;
