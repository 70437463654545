import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EmployeesDelete,
  EmployeesPost,
  EmployeesPut,
  getEmployeesSingle,
} from "../../../api";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getPositionQuery, getProfileQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const values = {
  phoneNumber: "",
  firstName: "",
  lastName: "",
  middleName: "",
  birthDate: "",
  positionId: null,
  state: null,
};

const EmployeesSingle = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const clients = useQueryClient();
  const schema = createSchema({
    phoneNumber: "phone",
    firstName: "name",
    lastName: "name",
    middleName: "name",
    birthDate: "birthday",
    positionId: "select",
    state: "select",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useHookForm(values, schema);

  const { data, isLoading } = useQuery({
    queryKey: ["employeesSingle", id],
    queryFn: () => getEmployeesSingle(id),
  });
  const { data: position } = useQuery(getPositionQuery());

  const { mutate } = useMutation({
    mutationFn: (id) => EmployeesDelete(id),
  });

  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });

  const [modal, setModal] = useState(false);

  function openModal() {
    setModal(!modal);
  }

  const Delete = (id) => {
    setDelLoading(true);
    mutate(id, {
      onSuccess: () => {
        // navigate(
        //   `${
        //     profile?.result?.id === data?.result?.id
        //       ? "/login"
        //       : "/employees"
        //   } `
        // );
        if (profile?.result?.id === data?.result?.id) {
          localStorage.removeItem("token"); // localStorage dan tokenni o'chirish
          navigate("/login");
        } else {
          navigate("/employees");
        }
        clients.invalidateQueries({ queryKey: ["employees"] });
      },
      onError: (error) => {
        alert(error.data?.error?.errorMessage);
      },
      onSettled: () => {
        setDelLoading(false);
      },
    });
  };
  useEffect(() => {
    if (data) {
      const formattedData = {
        ...data.result,
        birthDate: data.result.birthDate
          ? new Date(data.result.birthDate)
          : null,
        positionId: data.result.position
          ? { value: data.result.position.id, label: data.result.position.name }
          : null,
        state:
          data.result.state !== null
            ? {
                value: data.result.state,
                label: data.result.state === 1 ? t("Active") : t("Not active"),
              }
            : null,
      };
      reset(formattedData);
    }
  }, [data, reset, t]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const positionId = formData.positionId.value;
    const state = formData.state.value;
    const datas = {
      ...data.result,
      ...formData,
      state: state,
      positionId: positionId,
    };

    try {
      const response = await EmployeesPost(datas, "PUT");
      clients.invalidateQueries({ queryKey: ["employees"] });
      clients.invalidateQueries({ queryKey: ["employeesSingle", id] });
      reset();
      toast(t("Employee updated"), { type: "success", theme: "colored" });
      navigate("/employees");
    } catch (error) {
      alert(error.data?.error?.errorMessage);
      toast(error.data?.error?.errorMessage, {
        theme: "colored",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="page-content w-75">
      <ToastContainer position="top-center" />

      <div className="d-flex align-items-start gap-3">
        <i
          className="ri-arrow-left-line fs-3 cursor-pointer"
          onClick={() => navigate("/employees")}
        ></i>
        <h2>{t("Adding an employee")}</h2>
      </div>
      <div></div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <Label>{t("First name")}</Label>
              <Input name="firstName" control={control} errors={errors} />
            </div>
            <div className="col-md-6">
              <Label>{t("Last name")}</Label>
              <Input name="lastName" control={control} errors={errors} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Label>{t("Middle name")}</Label>
              <Input name="middleName" control={control} errors={errors} />
            </div>
            <div className="col-md-6">
              <Label>{t("Birth date")}</Label>
              <Controller
                name="birthDate"
                control={control}
                render={({ field }) => (
                  <Flatpickr
                    className="form-control"
                    options={{ dateFormat: "d M, Y" }}
                    onChange={(date) => field.onChange(date[0])}
                    value={field.value}
                    placeholder={t("Birth date")}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Label for="positionId">{t("Position")}</Label>
              <Controller
                name="positionId"
                control={control}
                className="bg-white"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={position?.result?.items?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    placeholder={t("Select Position")}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Label for="state">{t("State")}</Label>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: 0, label: t("Not active") },
                      { value: 1, label: t("Active") },
                    ]}
                    placeholder={t("Select State")}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Label>{t("Phone Number")}</Label>
              <Input name="phoneNumber" control={control} errors={errors} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end w-100 mt-4">
            <Button
              onClick={() => openModal()}
              className="btn btn-danger d-flex align-items-center gap-1"
            >
              <i className="ri-delete-bin-5-fill"></i>
              {t("Delete")}
            </Button>
            <Button className="d-flex align-items-center gap-1">
              {loading ? <Spinner size="sm" className="me-2" /> : null}
              {t("Save")}
              <i className="ri-check-line"></i>
            </Button>
          </div>
        </Form>
      )}

      <Modal
        isOpen={modal}
        toggle={() => {
          openModal();
        }}
        modalClassName="zoomIn"
        centered
        size="sm"
      >
        <ModalHeader>{t("Confirm deletion")}</ModalHeader>
        <ModalBody>
          <i
            className=" ri-alert-fill text-warning d-flex justify-content-center"
            style={{ fontSize: "50px" }}
          ></i>
          <p className="text-center">
            После того, как данные удалены, они не могут быть восстановлены!!!
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModal(false)}>{t("Cancel")}</Button>
          <Button onClick={() => Delete(id)} className="btn btn-danger">
            {delLoading ? <Spinner size="sm" className="me-2" /> : null}{" "}
            {t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EmployeesSingle;
