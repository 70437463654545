import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ProductState } from "../../../api";
import { useQueryClient } from "@tanstack/react-query";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Button,
} from "reactstrap";

import { t } from "i18next";

const StateSelect = ({ id, data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const clients = useQueryClient();
  const options = [
    { value: 1, label: t("Product active") },
    { value: 3, label: t("Product deactivated") },
    { value: 9, label: t("Product removed") },
  ];
  useEffect(() => {
    if (data?.result?.state) {
      setSelectedValue(data.result.state);
    }
  }, [data]);

  const stateSubmit = async (res) => {
    setLoading(true);
    try {
      await ProductState(id, { state: selectedValue });
      clients.invalidateQueries({ queryKey: ["products-id", id] });
      clients.invalidateQueries({ queryKey: ["products"] });
      clients.invalidateQueries({ queryKey: ["productsEdit", id] });
      setModalOpen(false);
    } catch (error) {
      alert(error.data?.error?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    handleModalToggle();
  };

  const Texts = () => {
    switch (selectedValue) {
      case 1: {
        return t("Do you want to activate a product?");
      }
      case 3: {
        return t("Are you sure you want to deactivate the product?");
      }
      case 9: {
        return t("Once data is deleted, it cannot be recovered!!!");
      }

      default: {
        return "";
      }
    }
  };
  return (
    <div>
      <div>
        <Select
          options={options}
          onChange={handleSelectChange}
          value={options.find((option) => option.value === selectedValue)}
        />
        <Modal centered size="sm" isOpen={modalOpen} toggle={handleModalToggle}>
          <ModalHeader>
            {selectedValue === 9 ? t("Confirm deletion") : t("Confirmation")}
          </ModalHeader>
          <ModalBody
            className="text-center "
            style={{ fontWeight: 550, color: "grey" }}
          >
            {selectedValue === 9 ? (
              <>
                <i
                  className="ri-alert-fill text-warning d-flex justify-content-center"
                  style={{ fontSize: "50px" }}
                ></i>
                {Texts()}
              </>
            ) : (
              Texts()
            )}
          </ModalBody>
          <ModalFooter className="d-flex align-items-center gap-2 justify-content-end">
            <Button
              className=" d-flex align-items-center"
              onClick={() => setModalOpen(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={
                selectedValue === 9 ? "btn btn-danger" : "btn btn-primary"
              }
              onClick={stateSubmit}
              disabled={loading}
            >
              {loading ? (
                <Spinner size={"sm"} />
              ) : selectedValue === 9 ? (
                t("Delete")
              ) : (
                "OK"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default StateSelect;
